import React, {useEffect, useMemo, useRef} from 'react';
import WebMercatorViewport from 'viewport-mercator-project'
import {ViewState} from 'react-map-gl';
import {ScalePower} from 'd3-scale';
import Flatbush from 'flatbush';
import styled from '@emotion/styled';
import {ascending, descending} from 'd3-array';
import {formatCount, TEXT_COLOR} from './constants';

interface Props {
  countsData: { id: string, name:string, centroid: [number,number], count: number | undefined }[] | undefined;
  viewport: ViewState;
  sizeScale: ScalePower<number, number> | undefined;
}

const Svg = styled.svg(`
  position: absolute;
  left:0;top:0;
  width: 100%;height:100%;
`);
const LabelG = styled.g(`
  opacity: 0;
`);

const LabelText = styled.text(`
  transition: opacity 0.5s;
  text-anchor: middle;
  alignment-baseline: middle;
  font-size:  8px;
  paint-order: stroke;
  fill: ${TEXT_COLOR};
  stroke: #fff;
  stroke-width: 1px;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  // font-weight: normal;
  font-weight: bold;
`);

const pad = 5;

const LabelsOverlay: React.FC<Props> = (props) => {
  const {countsData, viewport, sizeScale} = props;
  const ref = useRef<SVGGElement>(null);

  const sortedCountsData = useMemo(() => {
    if (!countsData) return undefined;
    return countsData.slice()
      .sort((a,b)=>descending(a.count||0,b.count||0));
  },[countsData]);
  useEffect(() => {
    if (ref.current && sortedCountsData) {
      const index = new Flatbush(sortedCountsData.length);
      const textNodes = ref.current.querySelectorAll('.label');
      for (let i = 0; i < textNodes.length; i++) {
        const {left, top, right, bottom} = textNodes[i].getBoundingClientRect();
        index.add(left, top, right, bottom);
      }
      index.finish();

      const toOmit = new Set<number>();
      // assuming they are sorted by size
      for (let i = textNodes.length - 1; i >= 0 ; i--) {
        if (toOmit.has(i)) continue;
        const textNode = textNodes[i];
        const {left, top, right, bottom} = textNode.getBoundingClientRect();
        const indices = index.search(left - pad, top - pad, right + pad, bottom + pad);
        for (const idx of indices) {
          if (idx !== i) {
            toOmit.add(idx);
          }
        }
      }
      for (let i = 0; i < textNodes.length; i++) {
        // @ts-ignore
        textNodes[i].style.opacity = toOmit.has(i) ? '0' : '1';
      }
    }
  }, [viewport.zoom, ref.current, sortedCountsData])

  if (!sortedCountsData || !sizeScale) return null;

  const mercator = new WebMercatorViewport(viewport);
  // @ts-ignore
  const {width,height} = viewport;
  return (
    <Svg width={width} height={height}>
    <g ref={ref}>
      {sortedCountsData
      .map((d, i) => {
        const [cx, cy] = mercator.project(d.centroid);
        const r = sizeScale(d.count || 0);
        const scale = Math.max(1,Math.min(2.5,Math.pow(viewport.zoom,3)*r/300))/1.5;
        return (
          <LabelG
            key={d.id}
            className="label"
            // transform={`translate(${cx},${cy /*+ (r < 10 ? r * 1.2 + scale*5: 0)*/})scale(${scale})`}
            transform={`translate(${cx},${cy + (r + scale*5)})scale(${scale})`}
          >
            <LabelText>
              {d.name}
            </LabelText>
            <LabelText
              y={10}
            >
              {formatCount(d.count)}
            </LabelText>
          </LabelG>
        );
      }).reverse()}
    </g>
    </Svg>
  );
};

export default LabelsOverlay;