import * as React from 'react';
import styled from '@emotion/styled';
import Away from './Away';
import {BASE_COLOR} from './constants';

export enum Direction {
  LEFT,
  RIGHT,
}

const DARK_GRAY3 = '#293742';
const DARK_GRAY4 = '#30404D';
const LIGHT_GRAY4 = '#F5F5F5';
const LIGHT_GRAY5 = '#F5F5F5';

export interface Props {
  height: number;
  initialCollapsed?: boolean;
  direction: Direction;
  darkMode: boolean;
  collapsedView?: React.ReactNode;
  isCollapsed: boolean;
  onChange: (collapsed: boolean) => void;
}

const Outer = styled.div`
  display: flex;
  justify-items: center;
  flex-direction: column;
`;

type BodyProps = { direction: Direction; collapsed: boolean; height: number };
const Body = styled.div<BodyProps>(
  ({ direction, collapsed, height }: BodyProps) => `
  order: ${direction === Direction.LEFT ? 2 : 1};
  overflow: hidden;
  max-height: ${collapsed ? 0 : `${height}px`};
  transition: max-width 0.15s ease-out;  
`
);

type ContentProps = { direction: Direction; height: number };
const Content = styled.div<ContentProps>(
  ({ direction, height }: ContentProps) => `
  height: ${height}px;
`
);

type RotateProps = { degrees: number };
const Rotate = styled.div<RotateProps>(
  ({ degrees }: RotateProps) => `
  transform-origin: center;
  transform: rotate(${degrees}deg)translate(0,3px);
  transition: transform 0.15s ease-out;  
`
);

type ButtonProps = {
  collapsed: boolean;
  direction: Direction;
  darkMode: boolean;
};

const Button = styled.button<ButtonProps>(
  ({ collapsed, direction, darkMode }: ButtonProps) => `
  display: flex;
  order: ${direction === Direction.LEFT ? 1 : 2};  
  border: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  background-color: transparent;
  // background-color: ${darkMode ? DARK_GRAY4 : LIGHT_GRAY5};
  color: #ccc;
  // border-radius: ${collapsed ? 4 : 0}px;
  border-radius: 4px;
  transition: background-color 0.25s, border-radius 0.15s;
  outline: none;  
  &:hover {
    // background-color: ${darkMode ? DARK_GRAY3 : LIGHT_GRAY4};
  }
`
);


const Chevron = () => (
  <svg width={20} height={20} viewBox="0 0 20 20">
    <path
      fill={BASE_COLOR}
      d="M4 11c.28 0 .53-.11.71-.29L10 5.41l5.29 5.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71l-6-6A.997.997 0 0010 3c-.28 0-.53.11-.71.29l-6 6A1.003 1.003 0 004 11zm6.71-1.71A.997.997 0 0010 9c-.28 0-.53.11-.71.29l-6 6a1.003 1.003 0 001.42 1.42l5.29-5.3 5.29 5.29c.18.19.43.3.71.3a1.003 1.003 0 00.71-1.71l-6-6z"
    />
  </svg>
);
const LinkText = styled.div`
  font-size: 12px;
  color: ${BASE_COLOR};
  padding: 0 10px;
  min-width: 70px; 
`;

export default class Collapsible extends React.Component<Props, {}> {

  bodyRef = React.createRef<HTMLDivElement>();

  handleClick = () => {
    this.props.onChange(!this.props.isCollapsed);
  };

  getArrow = () => {
    const { isCollapsed, direction } = this.props;
    switch (direction) {
      case Direction.LEFT:
        return <Rotate degrees={isCollapsed ? 180 : 360}><Chevron/></Rotate>;
      case Direction.RIGHT:
        return <Rotate degrees={isCollapsed ? 180 : 360}><Chevron/></Rotate>;
    }
  };

  render() {
    const { isCollapsed, height, direction, darkMode, children, collapsedView } = this.props;
    return (
      <Outer>
        <Body ref={this.bodyRef} height={height} direction={direction} collapsed={isCollapsed}>
          <Content height={height} direction={direction}>
            {children}
          </Content>
        </Body>
        {isCollapsed &&
          collapsedView
        }
        <Button
          darkMode={darkMode}
          collapsed={isCollapsed}
          direction={direction}
          onClick={this.handleClick}
        >
            {this.getArrow()}
            <LinkText>{isCollapsed ? "по месяцам" : "всего"}</LinkText>
            {this.getArrow()}
        </Button>
      </Outer>
    );
  }
}
