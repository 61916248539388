import {formatDefaultLocale} from 'd3-format';
import {format} from 'd3-format';
import _MESSAGES from './messages.json';
import _CONFIG from './config.json';
import {timeFormat, timeParse} from 'd3-time-format';
import {hcl, rgb} from 'd3-color';
import {MapController} from '@deck.gl/core';
import queryString from 'querystring';

formatDefaultLocale(require('d3-format/locale/ru-RU.json'));
type Country = 'ru';
type Language = 'ru';
export const SHOW_RELATIVE: boolean = false;
export const BASE_COLOR = '#F04E23';
export const TEXT_COLOR = '#2D2D2D';
export const BACKGROUND_COLOR = '#DDDAD8';
export const SHAPE_LINE_COLOR = [150, 100, 100];
export const SHAPE_FILL_COLOR = colorAsRgba('#FAEBE5ff');
export const CIRCLE_LINE_COLOR = [255, 255, 255];
export const CIRCLE_LINE_COLOR__HIGHLIGHTED = colorAsRgba(
  // @ts-ignore
  hcl(BASE_COLOR).darker()
);
export const CIRCLE_FILL_COLOR = colorAsRgba(BASE_COLOR);
const FALLBACK_COLOR_RGBA: RGBA = [255, 255, 255, 255];
export const formatCount = format(',.0f') as ((v: number | undefined) => string);
export const queryParams = queryString.parse(document.location.search.substr(1));
export const COUNTRY = (queryParams.country ?? 'ru') as Country;
export const ANIMATION_DURATION = 1000;
const LANG: Language = (() => {
  if (queryParams.lang) {
    return queryParams.lang as Language;
  }
  switch (COUNTRY) {
    default:
      return 'ru';
  }
})();
export const CONFIG: any = _CONFIG[COUNTRY];
export const MESSAGES = _MESSAGES[COUNTRY][LANG]!;
const LOCALE = (() => {
  switch (LANG) {
    case 'ru':
      return 'ru';
  }
})();
export const parseDate = timeParse('%Y-%m');
export const formatDateIso = timeFormat('%Y-%m');
export const formatDate =
  (d: Date) => {
    if (!d) return '…';
    return d.toLocaleDateString(LOCALE, {
      month: 'long',
      year: 'numeric',
    });
  };
export const shortFormatDate = (d: Date) => {
  return d.toLocaleDateString(LOCALE, {
    month: 'long',
  });
};
export const COUNTS_FILE_PATH =
  '/data/counts.csv';
// `https://docs.google.com/spreadsheets/d/e/2PACX-1vRE88iZXese8FnbmB-er-0ldyR9BSdu1zQCBDX4sBpw_nEV2GmuG1u7kOriPicirMZU35xSbGtvixFI/pub?gid=491777008&single=true&output=tsv`;
export const DARK_MODE = queryParams.dark ? queryParams.dark === 'true' : false;
export const FOCUS_PERIOD_START_DATE = parseDate(CONFIG.focusStartDate)!;
export const REFERENCE_PERIOD_START_DATE = CONFIG.referenceStartDate ? parseDate(CONFIG.referenceStartDate) : undefined;
export const REFERENCE_WEEKDAY_PATTERN = CONFIG.referenceWeekdayPattern;
export const INITIAL_VIEWSTATE = {
  latitude: 0, longitude: 0, zoom: 1,
  width: window.innerWidth,
  height: window.innerHeight,
};
const accessToken = process.env.REACT_APP_MapboxAccessToken;
const mapboxMapStyle =
  'mapbox://styles/ilyabo/ckh8e1jbd1f7s19o2qfr3jizf';
export const REF_DATE_WEEKDAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const CONTROLLER_OPTIONS = (() => {
  const base = {
    type: MapController,
    doubleClickZoom: false,
    dragRotate: false,
    touchRotate: false,
  };

  switch (COUNTRY) {
    case 'ru':
      return {
        ...base,
        minZoom: 0,
        maxZoom: 15,
      };
  }
})();
type RGBA = [number, number, number, number];

export function colorAsRgba(color: string): RGBA {
  const c = rgb(color);
  return [Math.floor(c.r), Math.floor(c.g), Math.floor(c.b), Math.round(c.opacity * 255)];
}