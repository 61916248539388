import React, { useLayoutEffect, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { scaleLinear } from 'd3-scale';
import { Column, Row } from './Boxes';
import {MESSAGES} from './constants';

const ColorRampCanvas = styled.canvas({
  display: 'flex',
  flexGrow: 1,
  border: `1px solid #666`,
});

const Title = styled.div({
  marginBottom: 5,
  fontWeight: 'bold',
});

const TickRow = styled(Row)({
  width: '100%',
  height: 5,
  position: 'relative',
  '& > div': {
    position: 'absolute',
    borderRight: `1px solid #666`,
    // flexGrow: 1,
    height: '100%',
  },
});

const ColorRampTextRow = styled.div({
  width: '100%',
  height: 10,
  position: 'relative',
  '& > *': {
    position: 'absolute',
  },
});

const ColorRamp: React.FC<{
  getColor: (v: number) => string;
  domain: [number, number],
  width?: number;
  height?: number;
  reverse?: boolean;
}> = props => {
  const { width = 60, height = 13, domain, getColor, reverse } = props;

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const xScale = useMemo(() =>
    scaleLinear()
      .domain(domain)
      .range([0, width]),
    [width, domain]
  );

  useLayoutEffect(() => {
    const { current } = canvasRef;
    if (current) {
      const ctx = current.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, width, height);
        for (let i = 0; i < width; i++) {
          ctx.fillStyle = getColor(xScale.invert(i));
          ctx.fillRect(reverse ? width - i - 1 : i, 0, 1, height);
        }
      }
    }
  });

  if (domain[0] === domain[1]) return null;
  return (
    <Column>
      <Title>
        {MESSAGES.numberOfTrips}
      </Title>
      <ColorRampCanvas ref={canvasRef} width={width} height={height} />
      {/*<TickRow>*/}
      {/*  <div style={{ left: 0 }}/>*/}
      {/*  <div style={{ left: xScale(0) }}/>*/}
      {/*  <div style={{ left: xScale.range()[1]+1 }}/>*/}
      {/*</TickRow>*/}
      <ColorRampTextRow>
        <div>{formatPercent(domain[0])}</div>
        {/*<div style={{ right: xScale.range()[1] - xScale(0) - 15 }}>{formatPercent(0)}</div>*/}
        <div style={{ right: 0 }}>{formatPercent(domain[1])}</div>
      </ColorRampTextRow>
    </Column>
  );
};

export default ColorRamp;


function formatPercent(x: number) {
  return `${x > 0 ? '+' : ''}${Math.round(x*100)}%`;
}
