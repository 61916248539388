import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {MapView} from '@deck.gl/core';
import MapContainer from './MapContainer';
import {DeckGL} from '@deck.gl/react';
import {GeoJsonLayer, ScatterplotLayer} from '@deck.gl/layers';
import styled from '@emotion/styled';
import {FlyToInterpolator, ViewState, ViewStateChangeInfo} from 'react-map-gl';
import {feature} from 'topojson-client';
import useFetch from 'react-fetch-hook';
import {Feature, FeatureCollection} from 'geojson';
import {Topology} from 'topojson-specification';
import {tsvParse} from 'd3-dsv';
import {scaleLinear, scaleSequential, scaleSqrt} from 'd3-scale';
import {interpolateRdYlBu} from 'd3-scale-chromatic';
import {ascending, extent, max, sum} from 'd3-array';
import {Box, Column, PaddedBox, Row} from './Boxes';
import Select, {components as selectComponents} from 'react-select';
import {easeCubic, easeLinear} from 'd3-ease';
import Away from './Away';
import ColorRamp from './ColorRamp';
import {TimeSeriesDatum} from './TotalTimeSeries';
import {interpolateRgbBasis} from 'd3-interpolate';
import NoScrollContainer from './NoScrollContainer';
import Timeline from './Timeline';
import {timeMonth} from 'd3-time';
import Collapsible, {Direction} from './Collapsible';
import {fitBounds} from '@math.gl/web-mercator';
import turfBbox from '@turf/bbox';
import {matchesSearchQuery} from './matchesSearchQuery';
import {useWindowSize} from './useWindowResize';
import LabelsOverlay from './LabelsOverlay';
import {SpinningCircles} from './SpinningCircles';
import {
  ANIMATION_DURATION,
  BACKGROUND_COLOR,
  BASE_COLOR,
  CIRCLE_FILL_COLOR,
  colorAsRgba,
  CONFIG,
  CONTROLLER_OPTIONS,
  COUNTRY,
  COUNTS_FILE_PATH,
  DARK_MODE,
  FOCUS_PERIOD_START_DATE,
  formatCount,
  formatDate,
  formatDateIso,
  INITIAL_VIEWSTATE,
  MESSAGES,
  parseDate,
  queryParams,
  REF_DATE_WEEKDAYS,
  REFERENCE_PERIOD_START_DATE,
  REFERENCE_WEEKDAY_PATTERN,
  SHAPE_FILL_COLOR,
  SHAPE_LINE_COLOR,
  shortFormatDate,
  SHOW_RELATIVE,
  TEXT_COLOR
} from './constants';



interface DateOption {
  value: string,
  label: string,
  dayOfWeek: number;
  month?: number;
  date?: Date,
}

interface AvailableDates {
  reference: DateOption[];
  rest: DateOption[];
}

interface LocationOption {
  value: string,
  label: string,
  feature?: Feature,
  isGroup?: boolean;
  groupLevel?: number;
}

interface Datum {
  id: string;
  countDiff: number;
  refCount: number;
  relDiff: number;
}

export function mapTransition(duration: number = 2000) {
  return {
    transitionDuration: duration,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: easeCubic,
  };
}

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: ${BASE_COLOR};
`;

const Subtitle = styled.div`
  font-size: 10px;
  text-align: center;
  font-weight: normal;
`;

const SelectOuter = styled.div`
  width: 280px;
  & div {
    color: ${BASE_COLOR} !important;
    border-color: ${BASE_COLOR} !important; 
  }
`;

const LocationName = styled.div`
  font-weight: bold;
`;

const TermsLink = styled(Away)`
  text-align: center;
  color: ${BASE_COLOR};
  cursor: pointer;
  &:hover { 
    text-decoration: underline;
  }
`;

const MainInfoBox = styled(PaddedBox)`
  max-width: 360px;
`;

const MainInfoBoxContent = styled.div({
  alignItems: 'center',
  fontSize: 'medium',
  display: 'flex',
  // width: 270,
  padding: 15,
  flexDirection: 'column',
  '& > *+*': {
    marginTop: 10,
  }
});

const ColorRampBox = styled(Box)({
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  '& > *+*': {
    marginTop: 5,
  }
});


const SelectedLocationDetails = styled.div`
  font-size: 12px;
  text-align: center;
  //font-weight: bold;
  color: ${TEXT_COLOR};
  small {
    display: block;
    // color: #666;
    font-size: 12px;
    font-weight: normal;
  }
  .huge {
    font-size: 30px;
    font-weight: bold;
    color: ${BASE_COLOR};
  }
  a {
    //color: ${TEXT_COLOR};
  }
`;

const TooltipBox = styled(Box)`
  pointer-events: none;
  padding: 10px;
  max-width: 125px;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .huge {
    font-size: 30px;
    font-weight: bold;
    color: ${BASE_COLOR};
  }
  &>* + * {
     margin-top: 5px;
  }
`;

const Outer = styled(NoScrollContainer)`
  display: flex;
  flex-direction: column;
  color: ${TEXT_COLOR};
  a {
    color: ${BASE_COLOR};
  }
`;

const MapOuter = styled.div({
  display: 'flex',
  flexGrow: 1,
  position: 'relative',
});

const CenterBlock = styled.div`
  position: absolute;
  top: 0; left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
`;


const DeckGLOuter = styled.div<{
  darkMode: boolean;
  baseMapOpacity: number;
  cursor: 'crosshair' | 'pointer' | undefined;
}>(
  ({ cursor, baseMapOpacity, darkMode }) => `
  #deckgl-wrapper {
    background: ${BACKGROUND_COLOR};
  }
  & #deckgl-overlay {
    // mix-blend-mode: ${darkMode ? 'screen' : 'multiply'};
  }
  & .mapboxgl-map {
    opacity: ${baseMapOpacity}
  }
  ${cursor != null ? `& #view-default-view { cursor: ${cursor}; }` : ''},
`
);

const SelectOption: React.FC<any> = (props) => {
  const {
    groupLevel,
    isGroup,
  } = props.data as LocationOption;
  return (
    <div
      style={{
        ...isGroup && {
          fontStyle: 'italic',
      },
        paddingLeft: (groupLevel ?? 0)*7,
      }}>
      <selectComponents.Option
        {...props}
        isDisabled={isGroup}
      />
    </div>
  );
}

function makeDateOption(dateStr: string): DateOption {
  const date = parseDate(dateStr)!;
  return {
    value: dateStr,
    label: formatDate(date),
    date: date,
    dayOfWeek: date?.getDay(),
    month: date?.getMonth(),
  };
}

function makeRefDateOption(dateStr: string): DateOption {
  if (REFERENCE_WEEKDAY_PATTERN) {
    let dayOfWeek = -1, month = -1;
    const m = new RegExp(REFERENCE_WEEKDAY_PATTERN).exec(dateStr);
    if (m && m[1] && m[2]) {
      month = +m[1] - 1;
      dayOfWeek = REF_DATE_WEEKDAYS.indexOf(m[2]);
    }
    return {
      value: dateStr,
      label: dateStr,
      date: undefined,
      dayOfWeek,
      month,
    };
  } else {
    return makeDateOption(dateStr);
  }
}


function pickInitialSelectedDate(availableDates: AvailableDates): DateOption {
  const desired = queryParams.date as string | undefined;
  let found;
  if (desired) {
    found = availableDates.rest.find(date => date.value === desired);
  }
  if (!found) found = availableDates.rest[availableDates.rest.length - 1];
  return found;
}

let numWarns = 0;
function pickRefDate(availableDates: AvailableDates, date: Date): DateOption | undefined {
  const dayOfWeek = date.getDay();
  const desired = queryParams.ref_date as string | undefined;
  let found;

  if (desired) {
    found = availableDates.reference.find(date => date.value === desired);
  }
  if (!found) {
    found = availableDates.reference.find((d) => {
        if (REFERENCE_WEEKDAY_PATTERN) {
          return date.getMonth() === d.month;
        } else if (REFERENCE_PERIOD_START_DATE) {
          return (
            d.date &&
            date.getMonth() === d.month &&
            d.date >= REFERENCE_PERIOD_START_DATE
          );
        }
        return false;
      }
    );
  }
  if (!REFERENCE_WEEKDAY_PATTERN && found) {
    const replacement =
      CONFIG.referenceDatesReplace &&
      (CONFIG.referenceDatesReplace as {[key: string]: string})[found.value];
    if (replacement) {
      found = availableDates.reference.find(date => date.value === replacement);
    }
  }
  if (!found && numWarns < 100) {
    numWarns++;
    console.warn(
      `No ref date for dayOfWeek ${dayOfWeek}`+
      ` ref weekday pattern = ${REFERENCE_WEEKDAY_PATTERN}`+
      ` ref period start = ${REFERENCE_PERIOD_START_DATE}`+
      ` ref dates = ${availableDates.reference.map(d => JSON.stringify(d)).join('\n')}`
    );
  }
  return found;
}

const NOCOLOR = [155, 155, 155, 50];

interface CircleItem {
  id: string;
  centroid: [number,number];
  count: number;
}

function App() {
  const [isCollapsed, setCollapsed] = useState(true);

  const size = useWindowSize();
  useEffect(() => {
    if (size)  // not sure why this doesn't fire from react-map-gl
      setViewport({
        ...viewport,
        ...size,
      });
  },[size?.width, size?.height]);

  const isNarrowScreen = size?.width != undefined && size.width < 400;
  const isShortScreen = size?.height != undefined && size.height < 600

  const fetchShapes = useFetch(`/data/${COUNTRY}/shapes.topo.json`);
  const geoJsonFeatures = useMemo(() => {
    const topology = fetchShapes.data as Topology<any>;
    if (topology) {
      return feature<any>(topology, topology.objects['zones']) as any as FeatureCollection;
    }
    return undefined;
  }, [fetchShapes.data]);

  const mapContainerRef = useRef<HTMLDivElement>(null);
  const [ tooltip, setTooltip ] = useState<any>();
  const [selectedLocation, setSelectedLocation] = useState<LocationOption | null>();
  const [x1,y1,x2,y2] = CONFIG.bbox;
  const [viewport, setViewport] = useState<ViewState>(INITIAL_VIEWSTATE);
  const initialViewState = fitBounds({
    width: window.innerWidth,
    height: window.innerHeight - 74,
    bounds: [[x1, y1], [x2, y2]],
    padding: window.innerWidth * 0.1,
  });
  useEffect(() => {
    setViewport(initialViewState);
  }, []);


  const fetchCounts = useFetch(
    COUNTS_FILE_PATH,
    { formatter: (response) =>
        response.text().then(text => tsvParse(text))
    }
  );

  const availableDates = useMemo(() => {
      if (!fetchCounts.data) return undefined;

      const dates = fetchCounts.data.columns.slice(1) as string[];
      const dateOptions = dates.sort((a, b) => ascending(a, b));
      return {
        reference: dateOptions.filter(d => {
          if (queryParams.ref_date) {
            return d === queryParams.ref_date;
          }
          if (REFERENCE_WEEKDAY_PATTERN) {
            return new RegExp(REFERENCE_WEEKDAY_PATTERN).test(d);
          }
          if (REFERENCE_PERIOD_START_DATE) {
            return parseDate(d)! >= REFERENCE_PERIOD_START_DATE;
          }
          return false;
        }).map(makeRefDateOption),
        rest: dateOptions
          .map(makeDateOption)
          .filter(d => d.date && d.date >= FOCUS_PERIOD_START_DATE)
        ,
      }
    },
    [fetchCounts.data]
  );

  const lastAvailableDate = useMemo(() => {
    if (availableDates) {
      const millis = max(availableDates.rest!.map(d => d.date?.getTime() || 0));
      if (millis) return new Date(millis);
    }
    return undefined;
  }, [availableDates]);

  const locationNamesById = useMemo(() => {
      if (!fetchCounts.data) return undefined;
      return fetchCounts.data.reduce((m: Map<string, string>, r: any) => {
        m.set(r.locationId, r.name);
        return m;
      }, new Map<string, string>());
    },
    [fetchCounts.data]
  );


  const datumByLocationByDate = useMemo(() => {
    if (!fetchCounts.data || !availableDates) return undefined;
    return fetchCounts.data
      .reduce((byLocationByDate, r: any) => {
        byLocationByDate.set(r.locationId,
          availableDates.rest.reduce((byDate, { date, value: dateStr }) => {
            const count = +(r[dateStr]|| 5);
            const refDate = date ? pickRefDate(availableDates, date)?.value : undefined;
            if (refDate) {
              const refCount = +(r[refDate]|| 5);
              const countDiff = count - refCount;

              if (CONFIG.filterMinCount == null ||
                 (count >= CONFIG.filterMinCount && refCount >= CONFIG.filterMinCount)
              ) {
                byDate.set(dateStr, {
                  id: r.locationId,
                  countDiff,
                  refCount,
                  relDiff: countDiff / refCount,
                });
              }
            }
            return byDate;
          }, new Map<string, Datum>())
        );
        return byLocationByDate;
      }, new Map<string, Map<string, Datum>>());
  }, [fetchCounts.data, availableDates]);

  const [selectedDateOption, setSelectedDateOption] = useState<DateOption>();
  if (availableDates != null && !selectedDateOption) {
    setSelectedDateOption(pickInitialSelectedDate(availableDates));
  }

  const cumulativeTotalByLocation = useMemo(() => {
    const selectedDate = selectedDateOption?.value;
    if (!datumByLocationByDate || !availableDates || !selectedDate) return undefined;
    const rv = new Map<string, number>();
    const dates = availableDates.rest.filter(d => d.value != null /* && d.value <= selectedDate*/).map(d => d.value);
    for (const [locationId, byDate] of datumByLocationByDate.entries()) {
      rv.set(
        locationId,
        sum(dates, d => byDate.get(d)?.countDiff || 0)
      );
    }
    return rv;
  }, [datumByLocationByDate, availableDates, selectedDateOption]);

  const refDateOption = useMemo(() => {
    if (!availableDates || !selectedDateOption?.date) return undefined;
    return pickRefDate(availableDates, selectedDateOption.date);
  }, [availableDates, selectedDateOption]);


  const getDefinedValues = useCallback(
    (date: string) => {
      if (!geoJsonFeatures || !datumByLocationByDate || !selectedDateOption) return undefined;

      const values: Datum[] = [];
      for (const f of geoJsonFeatures.features) {
        const d = datumByLocationByDate.get(f.properties!.id)?.get(date);
        if (d && d.relDiff != null) {
          values.push(d);
        }
      }

      if (values.length === 0) {
        console.warn(`No defined values for date ${date}`);
      }
      return values;
    },
    [geoJsonFeatures, datumByLocationByDate, selectedDateOption]
  );

  const calcTotalRelDiff = useCallback((date: string) => {
    if (!geoJsonFeatures || !datumByLocationByDate) return undefined;

    return datumByLocationByDate
      .get(selectedLocation ? selectedLocation.value : 'total')
      ?.get(date)
      ?.relDiff;

  }, [geoJsonFeatures, datumByLocationByDate, selectedLocation]);

  const calcTotalCountDiff = useCallback((date: string) => {
    if (!geoJsonFeatures || !datumByLocationByDate) return undefined;

    return datumByLocationByDate
      .get(selectedLocation ? selectedLocation.value : 'total')
      ?.get(date)
      ?.countDiff;

  }, [geoJsonFeatures, datumByLocationByDate, selectedLocation]);

  const totalTimeSeriesData = useMemo(
    () => {
      if (!availableDates) return undefined;
      const data: TimeSeriesDatum[] = [];
      for (const { value, date } of availableDates.rest) {
        const total = SHOW_RELATIVE ? calcTotalRelDiff(value) : calcTotalCountDiff(value);
        if (total != null) {
          data.push({
            date: date!,
            value: total,
          });
        }
      }
      return data;
    },
    [availableDates, calcTotalRelDiff, calcTotalCountDiff]
  );

  const totalDiff = useMemo(
    () => {
      if (!selectedDateOption) return undefined;
      return calcTotalRelDiff(selectedDateOption.value)
    },
    [calcTotalRelDiff, selectedDateOption]
  );

  const diffExtent = useMemo(
    () => {
      if (CONFIG.colorScaleDomain) return CONFIG.colorScaleDomain;

      if (!availableDates || !getDefinedValues) return undefined;

      let min: number | undefined = undefined;
      let max: number | undefined= undefined;
      for (const { value: date } of availableDates.rest) {
        const values = getDefinedValues(date);
        if (values) {
          const [a, b] = extent(values, (d: Datum) => d.relDiff);
          if (a != null && (min == null || a < min)) min = a;
          if (b != null && (max == null || b > max)) max = b;
        }
      }
      return [min, max];
    },
    [getDefinedValues, availableDates]
  );


  const colorScaleDomain = useMemo(() => {
    // [Math.abs(diffExtent[0]),-Math.abs(diffExtent[0])]
        // [totalDiff + width/2, totalDiff - width/2]
        // [totalDiff - width/2, totalDiff + width/2]

    // [.60,-.60]
    // return [-.8, .8] as [number, number];
    // return diffExtent as [number, number];
    return [0, diffExtent[1]] as [number, number];

  }, [diffExtent]);

  const getShapeColor = useMemo(() => {
      if (!colorScaleDomain) return undefined;
      // const width = Math.min(diffExtent[1] - diffExtent[0], 1);

      const interpolator = !DARK_MODE
        ? interpolateRdYlBu
        : interpolateRgbBasis(
          ['#5ffbb8', '#4ab686', '#357558', '#203b2e', '#000000', '#3e1a10', '#7a2a17', '#ba391d', '#ff4821']  // https://vis4.net/palettes/#/9|d|5ffbb8,000000|000000,ff4821|1|1
        );


      const color = scaleSequential(interpolator)
        .clamp(true);

      const negative = scaleLinear()
        .domain([colorScaleDomain[0], 0])
        .range([1.0, 0.5])
        .clamp(true);

      const positive = scaleLinear()
        .domain([0, colorScaleDomain[1]])
        .range([0.5, 0])
        .clamp(true);

      return (x: number) => {
        if (x < 0) {
          return color(negative(x));
        }
        return color(positive(x));
      };
    },
    [colorScaleDomain]
  );


  const getCountDiff = (f: Feature) => {
    if (datumByLocationByDate && selectedDateOption) {
      const countDiff = datumByLocationByDate
      .get(f.properties!.id)
      ?.get(selectedDateOption.value)
        ?.countDiff;
      if (countDiff != null && isFinite(countDiff)) {
        return countDiff;
      }
    }
    return undefined;
  };


  const highlightColor = colorAsRgba('#ff9645');
  const layers = [];
  if (geoJsonFeatures != null) {
    const lineColor = [170, 100, 100];
    layers.push(
      new GeoJsonLayer({
        id: 'choropleth',
        data: geoJsonFeatures.features,
        stroked: true,
        filled: true,
        opacity: 1.0,
        lineWidthUnits: 'pixels',
        getLineWidth: 0.5,
        // getLineColor: [255, 100, 100],
        getLineColor: SHAPE_LINE_COLOR,
        getFillColor: SHAPE_FILL_COLOR,
        pickable: true,
        // highlightColor: colorAsRgba('#a04e70'),
        // autoHighlight: true,
        onHover: (info: any) => {
          const id = info.object?.properties.id;
          setTooltip({
            ...info,
            id,
          });
        },
        onClick: (info: any) => {
          const id = info.object?.properties.id;
          if (selectedLocation && selectedLocation.value === id) {
            handleSelectLocation(undefined);
          } else {
            handleSelectLocation(locationOptionsById?.get(id));
          }
        },
      }),
    );
  }

  const circlesData = useMemo(() => {
    if (geoJsonFeatures != null && cumulativeTotalByLocation != null) {
      return geoJsonFeatures
      .features
      // .sort((a,b) =>
      //   descending(getCountDiff(a), getCountDiff(b)))
      .map(
        (d: Feature) => ({
          id: d.properties!.id,
          name: d.properties!.name,
          centroid: d.properties!.centroid,
          count: isCollapsed
            ? cumulativeTotalByLocation.get(d.properties!.id)
            : getCountDiff(d) || 0
        })
      )
    }
    return undefined;
  }, [geoJsonFeatures, datumByLocationByDate, selectedDateOption, isCollapsed]);



  const sizeScale = scaleSqrt().range(
    [0,isNarrowScreen ? 25 : 50]
  ).domain([0,isCollapsed ? 50000 : 5000])
  if (circlesData != null) {

    layers.push(
      new ScatterplotLayer({
        id: 'scatterplot-layer',
        data: circlesData,
        pickable: true,
        // opacity: 0.8,
        stroked: true,
        filled: true,
        radiusUnits: 'pixels',
        lineWidthScale: 1,
        lineWidthMinPixels: 1,
        lineWidthUnits: 'pixels',
        getPosition: (d: CircleItem) => d.centroid,
        getLineColor: [255, 255, 255],
        getRadius: (d: CircleItem) => sizeScale(d.count),
        // getFillColor: colorAsRgba('#D72327'),
        highlightColor: highlightColor,
        autoHighlight: true,
        getFillColor: CIRCLE_FILL_COLOR,
        // getFillColor: !SHOW_RELATIVE ? colorAsRgba('#a04e70') : (d: CircleItem) => {
        //   if (getShapeColor && datumByLocationByDate && selectedDateOption)
        //   {
        //     const relDiff = datumByLocationByDate
        //       .get(d.id)
        //       ?.get(selectedDateOption.value)
        //       ?.relDiff;
        //
        //     if (relDiff != null && isFinite(relDiff)) {
        //       return colorAsRgba(getShapeColor(relDiff));
        //     }
        //   }
        //   return NOCOLOR;
        // },
        transitions: {
          getFillColor: {
            duration: ANIMATION_DURATION,
            // easing: easeCubicInOut,
            easing: easeLinear,
            enter: (value: any) => [value[0], value[1], value[2], 0] // fade in
          },
          getRadius: {
            duration: ANIMATION_DURATION,
            // easing: easeCubicInOut,
            easing: easeLinear,
          },
        },
        updateTriggers: {
          getRadius: { circlesData },
          getFillColor: { getShapeColor, selectedDateOption }
        },
        onHover: (info: any) => {
          const id = info.object?.id;
          setTooltip({
            ...info,
            id,
          });
        },
        onClick: (info: any) => {
          const id = info.object?.id;
          if (selectedLocation && selectedLocation.value === id) {
            handleSelectLocation(undefined);
          } else {
            handleSelectLocation(locationOptionsById?.get(id));
          }
        }
      })
    );


    if (selectedLocation) {
      layers.push(
        new GeoJsonLayer({
          id: 'selected-outline',
          data: selectedLocation.feature,
          stroked: true,
          lineWidthUnits: 'pixels',
          getLineWidth: 3,
          filled: false,
          opacity: 1.0,
          getLineColor: highlightColor,
          pickable: false,
        }),
      )

    }
  }



  const locationOptions = useMemo(
    () => {
      if (!geoJsonFeatures  || !datumByLocationByDate) return undefined;

      const locations = geoJsonFeatures.features
        .filter(f => f.properties ? datumByLocationByDate.get(f.properties.id) != null : false)
        .map(f =>
          ({
            value: f.properties!.id,
            label: locationNamesById?.get(f.properties!.id) || f.properties!.name,
            feature: f,
          } as LocationOption)
        )
        .sort((a,b) => {
          if (a.label.startsWith('г.')) return -1;
          if (b.label.startsWith('г.')) return 1;
          return ascending(a.label, b.label)
        });

      return locations;
    },
    [geoJsonFeatures, datumByLocationByDate, locationNamesById]
  );


  const locationOptionsById = useMemo(
    () => {
      if (!locationOptions) return undefined;
      return locationOptions.reduce(
        (m, d) => { m.set(d.value, d); return m},
        new Map<string, LocationOption>(),
      )
    },
    [locationOptions]
  );

  const handleSelectLocation = (locationOption: LocationOption | undefined) => {
    let viewState;
    if (locationOption) {
      const { current } = mapContainerRef;
      if (current) {
        const [x1, y1, x2, y2] = turfBbox(locationOption.feature);
        viewState = fitBounds({
          width: current.clientWidth,
          height: current.clientHeight,
          bounds: [[x1, y1], [x2, y2]],
          padding: 50,
        });
      }
    } else {
      viewState = initialViewState;
    }

    if (viewState) {
      setViewport({
        ...viewState,
        ...mapTransition(),
      });
      setSelectedLocation(locationOption);
    }
  };

  const handleKeyDown = (evt: Event) => {
    if (evt instanceof KeyboardEvent && evt.key === 'Escape') {
      if (selectedLocation) {
        handleSelectLocation(undefined);
      }
      if (tooltip) {
        setTooltip(undefined);
      }
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleViewStateChange = ({ viewState }: ViewStateChangeInfo) => {
    setViewport(viewState);
    // setSelectedLocation(null);
    setTooltip(undefined);
  };


  const formattedTotalPercentage = formatPercentage(
    selectedLocation && datumByLocationByDate && selectedDateOption
      ? datumByLocationByDate
        .get(selectedLocation.value)
        ?.get(selectedDateOption.value)
        ?.relDiff
      : totalDiff
  );

  if (!fetchCounts.data || !fetchShapes.data) {
    return <Outer>
      <CenterBlock>
        <SpinningCircles/>
      </CenterBlock>
    </Outer>;

  }

  const cumulativeTotalDiffCount =
    cumulativeTotalByLocation?.get(selectedLocation != null ? selectedLocation.value : 'total');

  const cumulativeTotal = selectedDateOption?.date && (
    <div dangerouslySetInnerHTML={{
      __html: formatCumulativeCountDiff(cumulativeTotalDiffCount, formatDate(selectedDateOption.date))
    }}/>);

  const svobodaReference = (
    <div>Большинство из этих смертей - <Away href="https://www.svoboda.org/a/31097374.html">
      от COVID-19.
    </Away></div>
  );

  // const collapsedInfoBoxContent = (
  //   <MainInfoBoxContent>
  //     <LocationName>{selectedLocation ? selectedLocation.label : MESSAGES.country }</LocationName>
  //     {/*<DateWhenCollapsed>*/}
  //     {/*  {formatDate(selectedDateOption?.date!)}*/}
  //     {/*</DateWhenCollapsed>*/}
  //     <SelectedLocationDetails>
  //       {SHOW_RELATIVE ? formattedTotalPercentage :
  //         cumulativeTotal
  //       }
  //     </SelectedLocationDetails>
  //     {(cumulativeTotalDiffCount || 0) > 0 &&
  //       <SelectedLocationDetails>{svobodaReference}</SelectedLocationDetails>}
  //   </MainInfoBoxContent>
  // );
  //


  const getMainInfoBoxContent = (collapsed: boolean) => {
    return (
      <MainInfoBoxContent>
        <Column spacing={5}>
            <Title>{MESSAGES.title}</Title>
            <Subtitle>
              с марта 2020 {lastAvailableDate ? `по ${formatDate(lastAvailableDate)}` : ''} (в сравнении с 2019 г.)
            </Subtitle>
        </Column>

        {/*<LocationName>{selectedLocation ? selectedLocation.label : MESSAGES.country }</LocationName>*/}

        <Row spacing={10}>
          <SelectOuter>
            <Select<LocationOption>
              // @ts-ignore
              components={{Option: SelectOption}}
              // @ts-ignore
              isOptionDisabled={locationOption => locationOption.isGroup}
              onChange={handleSelectLocation as any}
              value={selectedLocation}
              options={locationOptions}
              isSearchable={isShortScreen}
              isClearable={true}
              isFixed={true}
              menuPortalTarget={document.body}
              escapeClearsValue={true}
              placeholder={MESSAGES.selectCity}
              filterOption={(option, input) => matchesSearchQuery(input, option.label)}
            />
          </SelectOuter>
        </Row>


        {!collapsed && totalTimeSeriesData &&
        <div style={{width: 300, height: 100}}>
          <Timeline
            totalTimeSeriesData={totalTimeSeriesData}
            start={availableDates ? availableDates.rest[0].date : undefined}
            end={availableDates ? availableDates.rest[availableDates.rest.length - 1].date : undefined}
            current={selectedDateOption?.date}
            formatDate={shortFormatDate}
            formatValue={(SHOW_RELATIVE ? formatPercentageShort : formatCount)}
            timeInterval={timeMonth}
            minTickWidth={70}
            stepDuration={ANIMATION_DURATION}
            onChange={(t) => {
              const found = availableDates?.rest?.find(d => d.value === formatDateIso(t));
              if (found) {
                setSelectedDateOption(found);
              }
            }}
          />
        </div>
        }

        {!collapsed && <SelectedLocationDetails>
          {SHOW_RELATIVE && formattedTotalPercentage}
          <div dangerouslySetInnerHTML={{
            __html: formatCountDiff(
              selectedDateOption ? (
                selectedLocation && datumByLocationByDate
                  ? datumByLocationByDate
                  ?.get(selectedLocation.value)
                  ?.get(selectedDateOption.value)
                    ?.countDiff
                  : calcTotalCountDiff(selectedDateOption.value)
              ) : undefined
            )}}/>
          {/*{` умерли за ${selectedDateOption?.label}`}*/}
        </SelectedLocationDetails>}

        {/*<SelectedLocationDetails>*/}
        {/*  {*/}
        {/*    cumulativeTotalDiffCount != null && selectedDateOption?.date &&*/}
        {/*    <CumulativeTotal>*/}
        {/*      {cumulativeTotal}*/}
        {/*    </CumulativeTotal>*/}
        {/*  }*/}
        {/*</SelectedLocationDetails>*/}
        {/*{(cumulativeTotalDiffCount || 0) > 0 &&*/}
        {/*<SelectedLocationDetails>{svobodaReference}</SelectedLocationDetails>}*/}

        {collapsed &&
        <>
          <SelectedLocationDetails>
            {SHOW_RELATIVE ? formattedTotalPercentage :
              cumulativeTotal
            }
          </SelectedLocationDetails>
          {(cumulativeTotalDiffCount || 0) > 0 &&
          <SelectedLocationDetails>{svobodaReference}</SelectedLocationDetails>}
        </>
        }

        </MainInfoBoxContent>
    )
  }


  const mainInfoBoxContent = (
    <Collapsible
      darkMode={DARK_MODE}
      initialCollapsed={true}
      height={250}
      direction={Direction.RIGHT}
      collapsedView={getMainInfoBoxContent(true)}
      isCollapsed={isCollapsed}
      onChange={setCollapsed}
    >
      {getMainInfoBoxContent(false)}
    </Collapsible>
  );

  return (
    <Outer>
      <MapOuter
        ref={mapContainerRef}
      >
        <MapContainer>

          <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
            {isNarrowScreen && mainInfoBoxContent}
            <div style={{position: 'relative', flexGrow: 1}}>
              <DeckGLOuter
                darkMode={DARK_MODE}
                baseMapOpacity={100}
                cursor="pointer"
                onMouseLeave={() => setTooltip(undefined)}
              >

                <DeckGL
                  repeat={true}
                  controller={CONTROLLER_OPTIONS}
                  layers={layers}
                  onViewStateChange={handleViewStateChange}
                  views={[new MapView({id: 'map', repeat: true})]}
                  viewState={viewport}
                  // initialViewState={INITIAL_VIEWSTATE}
                  // parameters={{
                  //   clearColor:
                  //     DARK_MODE ? [0, 0, 0, 1] : [255, 255, 255, 1],
                  // }}
                >
                  <LabelsOverlay
                    sizeScale={sizeScale}
                    countsData={circlesData}
                    viewport={viewport}
                  />
                  {/*<StaticMap*/}
                  {/*  mapboxApiAccessToken={accessToken}*/}
                  {/*  mapStyle={mapboxMapStyle}*/}
                  {/*  width="100%"*/}
                  {/*  height="100%"*/}
                  {/*/>*/}
                </DeckGL>
              </DeckGLOuter>
            </div>
          </div>


            {tooltip && tooltip.object && datumByLocationByDate && selectedDateOption && (
              <TooltipBox top={tooltip.y} left={tooltip.x}>
                <div>
                  {locationNamesById?.get(tooltip.id)}
                </div>
                {selectedDateOption && datumByLocationByDate &&
                <small
                  dangerouslySetInnerHTML={{__html:
                      formatCountDiff(
                        isCollapsed
                          ? cumulativeTotalByLocation?.get(tooltip.id)
                         : datumByLocationByDate
                              ?.get(tooltip.id)
                              ?.get(selectedDateOption.value)
                                ?.countDiff
                      )+
                      (isCollapsed ? '' :
                      ` умерли за ${formatDate(selectedDateOption.date!)} 
                        чем за ${formatDate(refDateOption?.date!)}`
                      )
                  }} />
                }
              </TooltipBox>
            )}


          {SHOW_RELATIVE && colorScaleDomain && getShapeColor && (
            <ColorRampBox left={10} bottom={35}>
              <ColorRamp
                width={180}
                height={20}
                domain={colorScaleDomain}
                getColor={getShapeColor}
              />
            </ColorRampBox>
          )}


            <Box left={7} bottom={7}>
              <Column padding={10} spacing={5}>
                <Row>
                  Источник данных:&nbsp;<TermsLink href={CONFIG.dataSourceUrl}>Росстат</TermsLink>
                </Row>
                <Row>
                  Разработка:&nbsp;<TermsLink href="https://github.com/ilyabo">Илья Бояндин</TermsLink>
                </Row>
              </Column>
            </Box>

            {mainInfoBoxContent && !isNarrowScreen &&
            <MainInfoBox left={0} top={0}>
                  {mainInfoBoxContent}
            </MainInfoBox>
            }

        </MapContainer>
      </MapOuter>

    </Outer>
  );
}



function formatPercentage(x: number | undefined) {
  if (x == null || !isFinite(x)) {
    return '…';
  }
  const v = Math.round(x*100);
  return (v > 0 ? MESSAGES.moreBy : MESSAGES.fewerBy).replace('{0}', `${Math.abs(v)}%`);
}

function formatCountDiff(v: number | undefined) {
  if (v == null || !isFinite(v)) {
    return '…';
  }
  return (v > 0 ? MESSAGES.moreByCount : MESSAGES.fewerByCount).replace('{0}', `${formatCount(Math.abs(v))}`);
}

function formatCumulativeCountDiff(v: number | undefined, selectedDate: string) {
  if (v == null || !isFinite(v)) {
    return '…';
  }
  return (
    v > 0
    ? MESSAGES.cumulativeMoreByCount
    : MESSAGES.cumulativeFewerByCount
    )
    .replace('{0}', selectedDate)
    .replace('{1}', `${formatCount(Math.abs(v))}`)
    ;
}

export function formatPercentageShort(x: number | undefined) {
  if (x == null || !isFinite(x)) {
    return '…';
  }
  const v = Math.round(x*100);
  return `${v > 0 ? '+' : '-'}${Math.abs(v)}%`;
}

export default App;

