import * as React from 'react';

const Away = (
  {
    href,
    className,
    children,
  }: {
    href: string;
    className?: string;
    children: React.ReactChild,
  }) => (
  <a href={href} className={className} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

export default Away;
