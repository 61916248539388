import React from 'react'
import {scaleLinear, scaleTime} from 'd3-scale';
import {extent} from 'd3-array';
import {line} from 'd3-shape';
import styled from '@emotion/styled';
import {BASE_COLOR, formatDate} from './constants';


const TOTAL_TIME_SERIES_MARGIN = {
  top: 12,
  right: 65,
  bottom: 20,
  left: 5,
};

export interface TimeSeriesDatum {
  date: Date;
  value: number;
}

interface Props {
  data: TimeSeriesDatum[];
  selected?: Date;
  width: number;
  height: number;
  formatValue: (v: number | undefined) => string;
};

const Outer = styled.div({
  display: 'flex',
  alignSelf: 'center',
});

const TinyText = styled.text({
  fontSize: 9,
  fill: BASE_COLOR,
});

const ZeroLine = styled.line({
  stroke: BASE_COLOR,
});

const DashedLine = styled.line({
  stroke: BASE_COLOR,
  strokeDasharray: "1,2",
});

const DateText = styled.text({
  fontSize: 12,
  fontWeight: 'bold',
  fill: BASE_COLOR,
});

const TotalTimeSeries: React.FC<Props> = (
  {
    width,
    height,
    data,
    formatValue,
    selected,
  }
) => {
  const margin = TOTAL_TIME_SERIES_MARGIN;
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  const timeExtent = extent(data, d => d.date);
  if (timeExtent[0] == null || timeExtent[1] == null) return null;
  const timeScale = scaleTime()
    .domain(timeExtent)
    .range([0, innerWidth]);

  const valuesExtent = extent(data, d => d.value);
  if (valuesExtent[0] == null || valuesExtent[1] == null) return null;
  const yScale = scaleLinear()
    .domain([valuesExtent[0], Math.max(0, valuesExtent[1])])
    .range([innerHeight, 0]);

  // @ts-ignore
  const path = line().x(d => timeScale(d.date)).y(d => yScale(d.value))(data);

  return (
    <Outer>
      <svg width={width} height={height}>
        <g transform={`translate(${margin.left},${margin.top})`}>
          {/*<rect*/}
          {/*  x={0}*/}
          {/*  y={0}*/}
          {/*  width={innerWidth}*/}
          {/*  height={innerHeight}*/}
          {/*  fill="#f7f7f7"*/}
          {/*  />*/}
          {/*{*/}
          {/*  timeScale.ticks(timeDay).map(tick =>*/}
          {/*    <line*/}
          {/*      key={tick.getTime()}*/}
          {/*      x1={timeScale(tick)}*/}
          {/*      x2={timeScale(tick)}*/}
          {/*      y1={0}*/}
          {/*      y2={innerHeight}*/}
          {/*      stroke="#fff"*/}
          {/*    />*/}
          {/*  )*/}
          {/*}*/}
          <DashedLine
            x1={-margin.left}
            x2={innerWidth+5}
            y1={yScale.range()[1]}
            y2={yScale.range()[1]}
          />
          <ZeroLine
            x1={-margin.left}
            x2={innerWidth+10}
            y1={yScale(0)}
            y2={yScale(0)}
          />
          {/*<DashedLine*/}
          {/*  x1={-margin.left}*/}
          {/*  x2={innerWidth+5}*/}
          {/*  y1={yScale.range()[0]}*/}
          {/*  y2={yScale.range()[0]}*/}
          {/*/>*/}
          <TinyText
            x={innerWidth + 15}
            y={yScale(0) + 3}
            textAnchor="start"
          >{formatValue(0)}</TinyText>
          <TinyText
            x={innerWidth + 15}
            y={yScale.range()[1]+3}
            textAnchor="start"
          >{formatValue(yScale.domain()[1])}</TinyText>
          {/*<TinyText*/}
          {/*  x={innerWidth + 20}*/}
          {/*  y={yScale.range()[0]+3}*/}
          {/*  textAnchor="middle"*/}
          {/*>{formatPercentageShort(yScale.domain()[0])}</TinyText>*/}

          {/*<TinyText*/}
          {/*  x={-margin.left}*/}
          {/*  y={yScale(0)-5}*/}
          {/*>{`↑ ${MESSAGES.moreTrips}`}</TinyText>*/}
          {/*<TinyText*/}
          {/*  x={-margin.left}*/}
          {/*  y={yScale(0)+11}*/}
          {/*>{`↓ ${MESSAGES.fewerTrips}`}</TinyText>*/}
          <path
            d={path}
            style={{
              strokeWidth: 4,
              stroke: BASE_COLOR,
              strokeLinejoin: 'round',
              strokeLinecap: 'round',
              fill: 'none',
            }}
          />
          {selected &&
            <g transform={`translate(${timeScale(selected)},0)`}>
              <line
                y1={-10}
                y2={innerHeight + 10}
                stroke={BASE_COLOR}
              />
              <path
                transform="translate(0,-12)"
                d="M-5,0 0,7 5,0 z"
                style={{
                  strokeWidth: 1,
                  fill: BASE_COLOR,
                }}
              />
              <path
                transform={`translate(0,${innerHeight+15})`}
                d="M-5,0 0,-7 5,0 z"
                style={{
                  strokeWidth: 1,
                  fill: BASE_COLOR,
                }}
              />
              {selected &&
              <DateText
                {...(
                  (timeScale(selected) > innerWidth/2)
                  ? {x:-8, textAnchor: 'end'}
                  : {x:+8, textAnchor: 'start'}
                )}
                y={innerHeight+15}
              >
                {formatDate(selected)}
              </DateText>}
            </g>
          }
        </g>
      </svg>

    </Outer>
)
};


export default TotalTimeSeries;
